<template>
  <div id="thermal">
    <div class="page" size="A4">
      <v-row class="pt-2 align-center">
        <v-col class="d-flex flex-row align-center justify-center">
          <v-avatar size="60">
            <v-img
              src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>

          <b style="font-size: 15px">Rede Casas das Tintas</b>
        </v-col>
      </v-row>

      <hr class="my-4" />

      <v-row class="text-center">
        <v-col><h2>Romaneio de Entrega</h2> </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <VueBarcode
            height="30"
            :fontSize="10"
            :value="delivery.number"
            :text="`Entrega N° ${delivery.number}`"
          />
        </v-col>
        <v-col cols="12">
          <div><b>Venda :</b> N º{{ delivery.sale.number }}</div>
          <div><b>Vendedor :</b> {{ delivery.sale.salesman.short_name }}</div>
          <div>
            <b>Data Compra :</b>{{ $format.dateBr(delivery.sale.created_at) }}
          </div>
          <div>
            <b>Data Entrega :</b>{{ $format.dateBr(delivery.scheduled_to) }}
          </div>
          <div><b>Peso: </b>{{ delivery.sale.weight_gross }}</div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between align-center mb-2">
        <v-col>
          <b>Dados Do Cliente</b>
          <div>
            <template v-if="hasCustomer">
              <span>
                <b>Nome:</b>
                {{ delivery.sale.customer.code }} -
                {{ delivery.sale.customer.name }}
              </span>
              <br />
              <span v-if="delivery.sale.customer.phone_wa">
                WhatsApp:{{ delivery.sale.customer.phone_wa }}
              </span>
              <app-show-phones
                class="d-flex"
                style="justify-content: space-between"
                :phones="delivery.sale.customer.phones"
              />
            </template>

            <div v-else>
              <span> <b>Nome: </b> Consumidor Final </span>
            </div>

            <span v-if="delivery.main_address">
              <b>Endereço: </b>
              {{ delivery.main_address.address }},
              {{ delivery.main_address.number }},
              {{ delivery.main_address.district }},
              {{ delivery.main_address.city }}-
              {{ delivery.main_address.state }},
              {{ $format.cep(delivery.main_address.postal_code) }}
              <span v-if="delivery.main_address.complement">
                <br />
                {{ delivery.main_address.complement }}
              </span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="delivery_products"
            :items-per-page="-1"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.product`]="{ item }">
              <span v-if="item.product"
                >{{ item.product.code }} - {{ item.product.name }}</span
              >
              <br />
              [
              <span
                v-if="item.product_company"
                v-text="item.product_company.company.short_name"
              />
              ]
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.quantity) }}
                {{ item.product_variant.unity.symbol }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row
        style="word-break: break-word; padding-top: 20px; padding-bottom: 25px"
      >
        <v-col> Observação: {{ delivery.comment }} </v-col>
      </v-row>
      <v-row class="text-center mt-10">
        <v-col cols="12">
          <v-divider />
          Estoquista Responsável
        </v-col>
      </v-row>
      <v-row class="text-center mt-10">
        <v-col cols="12">
          <v-divider />
          Assinatura
        </v-col>
      </v-row>

      <br />
      Registrado em {{ $format.dateTimeBr(delivery.created_at) }}
      <br />
      Impresso em {{ $format.dateBr(date) }}
      <br />

      <div class="d-flex justify-center">
        <span v-for="i in 50" :key="i">.</span>
      </div>
    </div>
  </div>
</template>

<script>
import AppShowPhones from "@/components/app/sections/AppShowPhones.vue";
import VueBarcode from "vue-barcode";
import CompanyChip from "@/components/company/sections/CompanyChip.vue";

export default {
  components: { AppShowPhones, VueBarcode, CompanyChip },
  props: {
    delivery: {},
  },
  data() {
    return {
      headers: [
        { text: "Produto", value: "product" },

        { text: "Entrega	", value: "quantity", align: "center", width: "10%" },
      ],
      date: new Date().toLocaleString(),
    };
  },

  computed: {
    hasCustomer() {
      return this.delivery.sale.customer_id;
    },

    delivery_products() {
      return this.delivery.delivery_products;
    },
  },
  methods: {},
};
</script>
<style  lang="scss">
@import "@/assets/style/prints/ThermalPrinting.scss";
</style>

