<template>
  <div>
    <component :is="print_type" :delivery="delivery" />
  </div>
</template>

<script>
import common_printing from "@/components/delivery/section/CommonPrintPackingList.vue";
import thermal_printing from "@/components/delivery/section/PrintThermalPackingList.vue";
export default {
  components: {
    common_printing,
    thermal_printing,
  },
  data() {
    return {
      delivery: {
        sale: { customer: {} },
        main_address: {},
        delivery_products: [],
        company: {
          main_address: {},
        },
      },
    };
  },

  async created() {
    this.$loading.start();
    await this.$http
      .show("sale/delivery", this.$route.params.id)
      .then((response) => {
        this.delivery = response.delivery;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },
  computed: {
    print_type() {
      return this.$store.getters["app/getTypePrint"];
    },
  },
};
</script>

<style  scoped>
</style>